import React from 'react';
import ContentColumn from "../layout/ContentColumn";
import SideBar from "../layout/SideBar";
import TournamentBox from "../boxes/TournamentBox";

class TournamentIndexPage extends React.Component {
    constructor(props) {
        super(props);
        this._props = props;

        if (window._forceUpdate) {
            // Normal updates receive only few matches -> request full list for the index
            window._forceUpdate();
        }
    }

    getContentColumnContents() {
        return (
            <>
                <TournamentBox fullpage="true" />
            </>
        )
    }

    render() {
        return (
            <div className="Content">
                <ContentColumn content={this.getContentColumnContents()} />
                <SideBar />
            </div>
        );
    }
}

export default TournamentIndexPage;
