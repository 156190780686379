import './AdBox.css';
import React from 'react';
import { AppContext } from '../App.js'

class AdBox extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.popMediaAds();
    }

    componentDidUpdate(prevProps, prevState) {
        this.popMediaAds();
    }

    injectDeviceInfoIntoPopMediaAds() {
        var type = window.innerWidth < 980 ? 'mobile' : 'desktop';
        ["data-lazy-" + type + "-ad-unit-id", "data-" + type + "-ad-unit-id"].forEach(function(attr) {
            Array.from(document.querySelectorAll("["+ attr + "]")).forEach(function (elm){
                elm.setAttribute(attr.replace(type + "-", ""), elm.getAttribute(attr));
            });
        });
    }

    servePopMediaAds() {
        if (window._ad_init_done) {
            // Already defined -> skip!
            return;
        }
        window._ad_init_done = true;
        // @todo: Need to call between page loads (or ad slot updates):
        // @todo: see https://help.relevant-digital.com/knowledge/relevantdigital.destroyslots
        /**
         * if(window.relevantDigital && relevantDigital.destroySlots) {
         *    relevantDigital.destroySlots();
         * }
         */

        window.relevantDigital = window.relevantDigital || {};
        relevantDigital.cmd = relevantDigital.cmd || [];
        relevantDigital.cmd.push(function() {
            relevantDigital.loadPrebid({
                configId: '63e4ae374cc636ac3cc16da7', //pelaajatcom config id
                delayedAdserverLoading: true,
                manageAdserver: true,
                collapseEmptyDivs: true,
                collapseBeforeAdFetch: false,
                noGpt: true,
                allowedDivIds: null,
            });
        });
    }

    popMediaAds() {
        const element = document.querySelectorAll('[data-desktop-ad-unit-id]');
        if (element) {
            console.log('Element found:', element);
            this.injectDeviceInfoIntoPopMediaAds();
            this.servePopMediaAds();
        }
    }

    render() {
        if (!this.props?.position || !this.props?.context?.components?.reklaami) {
            return null;
        }
        const ad = this.props.context.components.reklaami[this.props.position];
        if (this.props.position === 'article' && ad === false) {
            // Serve PopMedia ads when nothing else is available
            const ad_html = '<div data-desktop-ad-unit-id="/22871113322/pelaajatcom_smart_in_content_1_desktop" data-mobile-ad-unit-id="/22871113322/pelaajatcom_smart_in_content_1"></div>';
            return (
                <div className="Ad-Box" dangerouslySetInnerHTML={{__html: ad_html}} />
            );
        }
        if (!ad) {
            return null;
        }

        if (ad.html) {
            return (
                <div className="Ad-Box" dangerouslySetInnerHTML={{__html: ad.html}} />
            );
        }

        let ad_content = null;
        if (ad.img_url) {
            ad_content = (
                <img src={ad.img_url} />
            );
        }
        if (ad.img_link) {
            ad_content = (
                <a href={ad.img_link} target="_blank">
                    {ad_content}
                </a>
            );
        }

        return (
            <div className="Ad-Box">
                {ad_content}
            </div>
        );
    }
}

const AdBoxWithContext = (props) => {
    return (<AppContext.Consumer>
        {(context) => {
            return <AdBox {...props} context={context} />
        }}
    </AppContext.Consumer>)
};
export default AdBoxWithContext;
