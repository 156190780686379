import React from 'react';
import CommunityFeedBox from "../boxes/CommunityFeedBox";
import ContentColumn from "../layout/ContentColumn";
import SideBar from "../layout/SideBar";
import {AppContext} from "../App";

class CommunityIndexPage extends React.Component {
    constructor(props) {
        super(props);
        this._props = props;
        this.state = {
            page: 0,
            is_loading: false,
        };
        this.tracking_scroll = null;

        if (window._forceUpdate) {
            window._forceUpdate();
        }
    }

    componentDidMount() {
        if (this.tracking_scroll) {
            return;
        }
        window.addEventListener('scroll', this._handleScroll.bind(this));
        this.tracking_scroll = true;
    }

    componentWillUnmount() {
        if (!this.tracking_scroll) {
            return;
        }
        window.removeEventListener('scroll', this._handleScroll.bind(this));
        this.tracking_scroll = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (window.location.pathname !== '/yhteisö') {
            this.componentWillUnmount();
        } else {
            this.componentDidMount();
        }
    }

    _handleScroll() {
        if (this.state.is_loading) {
            // Ignore -> do not spam
            return;
        }
        const feed = this.props?.context?.community;
        if (!feed || feed.length < 10) {
            // Ignore, since no more data available
            return;
        }
        const scrollPosition = window.scrollY + window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollPosition / documentHeight > 0.8) {
            this.setState({
                is_loading: true,
            });

            const next_page = this.state.page + 1;
            this.setState({
                page: next_page,
            });
            window._communityIndexPagination = next_page;
            window._forceUpdate().finally(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                setTimeout(() => {
                    this.setState({
                        is_loading: false,
                    });
                }, 100);
            });
        }
    }

    getContentColumnContents() {
        return (
            <>
                <CommunityFeedBox fullpage="true" />
            </>
        )
    }

    render() {
        return (
            <div className="Content">
                <ContentColumn content={this.getContentColumnContents()} />
                <SideBar />
            </div>
        );
    }
}

const CommunityIndexPageWithContext = (props) => {
    return (<AppContext.Consumer>
        {(context) => {
            return <CommunityIndexPage {...props} context={context} />
        }}
    </AppContext.Consumer>)
};
export default CommunityIndexPageWithContext;
